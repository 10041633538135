import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Image from './img/Engaging-an-AC-Compressor-Clutch.jpg'
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Para1 from './img/img/img9.jpeg';
import Cvmain from "./cv_joseph.pdf"
import Port from "./port"

import Atiefoo from './img/port/atiefo.PNG';
import Zf1 from './img/port/zf1.PNG';
import Zf12 from './img/port/zf12.PNG';
import Z from './img/port/z.PNG';
import Emador from './img/port/emador.PNG';
import AM from './img/port/3am.PNG';
import Jsk from './img/port/jsk2.PNG';
import Shop from './img/port/shopon.PNG';
import Book from './img/port/book.PNG';
import Portmain from './portmain';
import AOS from 'aos';
import "aos/dist/aos.css";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Public from '@material-ui/icons/Public';
import GitHub from '@material-ui/icons/GitHub';

import Particles from 'react-tsparticles' 

import TiltPhaseSix from './TiltPhaseSix';
import User from './img/port/Component301.png';
const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));







function Example(props)

{
     AOS.init({
    // initialise with other settings
    duration : 700
  });
//    const classes = useStyles();
    const classes = useStyles();
     
    
  var items = [
        {
            name: "My Portfolio",
            image: Para1,
            description: "We provide the best biomass wood pellet"
        }
      ]

    return (
        <div>
        <Carousel swipe={true} touch={true} animation={"slide"} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>

    <br/>
    <br/>


   <Container  maxWidth="lg" style={{textAlign:"left"}}>
    
   <h2 className="MuiTypography-h5" style={{textAlign:"Center"}} data-aos="fade-up">
        <b>Works we have done </b>
            </h2> 



 <Portmain/>
   
   
</Container>
    </div>

      
    )
}

function Item(props) {
    const classes = useStyles()
    return (

        <div>
            <div style={{ position: "absolute", width: "100%" }}>
                <Grid container spacing={5} >
                    <Grid sm={12} lg={5} md={5} style={{ marginTop: "8%" }}>




                        <div id="App" data-aos={"flip-right"} className={classes.large} style={{ color: "white", fontSize: "33px", marginTop: 30, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} width="100%" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                            <p className={classes.larger}>
                                We Provide Tech Solutions
                            </p>

                            <h4 className={classes.smaller}>
                                We Provide Tech Solutions
                            </h4>


                            <Button style={{ backgroundImage: "linear-gradient(315deg, #D29E00 0%,  #FFDE3B 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button>
                        </div>


                    </Grid>

                    <Grid sm={12} lg={5} md={5} style={{ marginTop: "17%" }}>




                    </Grid>

                </Grid>



            </div>

            <Particles className="particle" style={{ position: "", zIndex: 1, height: "100%", width: "200%", backgroundColor: "red" }}
                id="tsparticles"
                options={{
                    background: {
                        color: {
                            value: "",
                        },

                    },

                    fpsLimit: 30,
                    interactivity: {
                        detectsOn: "canvas",
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: false,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 0.5,
                                opacity: 0.8,
                                size: 40,
                            },
                            push: {
                                quantity: 10,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: ["#C39300", "#D5A100", "#FFDE3B"]
                        },
                        links: {
                            color: "#FFDE3B",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 0.5,
                        },
                        collisions: {
                            enable: false,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outMode: "bounce",
                            random: true,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 900,
                            },
                            value: 100,
                        },
                        opacity: {
                            value: 0.2,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: true,
                }}
            />




        </div>




    )
}


export default Example


