import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import AOS from 'aos';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import Particles from 'react-tsparticles'
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"
 

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
    },
  },
}));


function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });

    var items = [
        {
            name: "MOBILE APP DEVELOPMENT",
            image: Para0,
            description: "I love to solve problems with code"
        }
    ]


  const classes = useStyles();







    return (

        <div>


<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<h1 align="center">Register</h1>
<br/>
          <form className={classes.root} noValidate autoComplete="off">
     
      <TextField id="outlined-basic" type='text'  placeholder="Lastname"  variant="outlined"  />
  
     <TextField id="outlined-basic" type='text'  placeholder="Othername(s)"  variant="outlined"  />
     <br/>
     <TextField id="outlined-basic" type='tel' placeholder="Contact"  variant="outlined"  />
     <TextField id="outlined-basic" type='email' placeholder="Email"  variant="outlined"  />
   <br/>
     <TextField id="outlined-basic" type='address' placeholder="Address"  variant="outlined"  />
     <TextField id="outlined-basic" type='email' placeholder="Email"  variant="outlined"  />
   <br/>
   <input  type="submit" variant="outlined" style={{width:"44ch", padding:10, }}  />
   
   
    </form>



            <div>









                <div style={{ backgroundSize: "cover", paddingTop: 30, paddingBottom: 20 }}>


                    <Container maxWidth="sm" style={{ textAlign: "left", backgroundColor: "rgba(255,255,255,0.1)" }}>





                    </Container>

                </div>





            </div>




            <br />
            <br />
            <br />
            <br />

        </div>


    )
}

function Item(props) {
    return (



        <div>
            <div style={{ position: "absolute", width: "100%", zIndex: "1" }}>

                <h1 style={{ color: "black", fontSize: "32px", marginTop: "13%", textAlign: "center", zIndex: "1" }} data-aos={"flip-right"}><span style={{ paddingLeft: 12, paddingRight: 12, backgroundImage: "linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)" }}>{props.item.name} </span></h1>

                <a href={Cvmain} download ><Button style={{ backgroundImage: "linear-gradient(315deg, #D29E00 0%,  #FFDE3B 74%)", color: "white", zIndex: 1 }}>
                    Download CV
                </Button>
                </a>







            </div>

          



        </div>







    )
}


export default Example


