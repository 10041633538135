import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import Use2 from './img/use2.jpg';
import User from './img/port/Component33.png';
import User2 from './img/port/Component1.png';

import AOS from 'aos';
import "./index1.css";
import Particles from 'react-tsparticles' 
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"




function Example(props){
    AOS.init({
    // initialise with other settings
    duration : 700
  });
    
   


    return (
        

        <div id="styler2" >
        <Container maxWidth="lg" className="styler2" style={{ textAlign: "left", paddingTop: 50, paddingBottom: 50 }}>
            <h2 className="MuiTypography-h5" style={{ textAlign: "Center" }} data-aos="fade-up">
                <b>Portfolio</b>

            </h2>

         
         


                <Grid container spacing={0}>

                    



                    <Grid lg={6} md={6} sm={6} style={{marginTop:40}} >


                        <div class="container">
                        <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={User2} width="100%" alt="" />
                            </div>
                                <div class="content" >

                                    <h3> JSK Renewable Energy <br /><p>
                                        <a href="https://jskrenewable.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                    <br />
                            </div>
                        </div>



                    </div>


                </Grid>

                    
                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 40 }}>


                        <div class="container">
                        <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={User} width="100%" alt="" />
                            </div>
                                <div class="content" >
                                    
                                    
                                    <h3> JSK Renewable Energy <br /><p>
                                        <a href="https://jskrenewable.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                    
                            </div>
                        </div>



                    </div>


                </Grid>





            </Grid>





        </Container>
</div>



    )
}


export default Example


