import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Para0 from '../img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import AOS from 'aos';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import Particles from 'react-tsparticles'
import Table1 from './table'
import "aos/dist/aos.css";
 

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '40ch',
    },
  },
}));


function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });

    var items = [
        {
            name: "MOBILE APP DEVELOPMENT",
            image: Para0,
            description: "I love to solve problems with code"
        }
    ]


  const classes = useStyles();







    return (

        <Container>
<br/>
<br/>
<br/>
<br/>
<br/>

<Table1/>



            <Container>









                <div style={{ backgroundSize: "cover", paddingTop: 30, paddingBottom: 20 }}>


                    <Container maxWidth="sm" style={{ textAlign: "left", backgroundColor: "rgba(255,255,255,0.1)" }}>





                    </Container>

                </div>





            </Container>




            <br />
            <br />
            <br />
            <br />

        </Container>


    )
}

function Item(props) {
    return (



        <div>
            <div style={{ position: "absolute", width: "100%", zIndex: "1" }}>

                <h1 style={{ color: "black", fontSize: "32px", marginTop: "13%", textAlign: "center", zIndex: "1" }} data-aos={"flip-right"}><span style={{ paddingLeft: 12, paddingRight: 12, backgroundImage: "linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)" }}>{props.item.name} </span></h1>

               







            </div>

          



        </div>







    )
}


export default Example


