//import React from 'react';
import React from 'react';
import { useEffect } from 'react';

import  {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
//import './materialize.css';
import './style.css';

import Main from './home';
import Contact from './contact';
import Portfolio from './portfolio';
import Scientific from './scientific';
import Mobileapp from './mobileapp';
import Website from './websitedevelopment';
//import Profile from './profile';
import Services from './services';
import Login from './login';
import Register from './register';
import Aboutus from './aboutus';
import Footer from './footer';
import Logo from './img/logo.png';
import Cv from './cv';
import clsx from 'clsx';
//import welcome from './img/air-con-compressor-not-working-hero-image.jpg';
//import logo from './img/user2.jpg';
import { makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Menu1 from '@material-ui/icons/Menu';
import Call from '@material-ui/icons/Call';


const useStyles = makeStyles((theme)=>({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
    displayer_small:{
        [theme.breakpoints.up('xs')]: {
        display:'flex',
        },
         [theme.breakpoints.up('sm')]: {
       display:'none',
        }
    },
    displayer_big:{
        [theme.breakpoints.up('xs')]: {
        display:'none',
        },
         [theme.breakpoints.up('sm')]: {
       display:'flex',
        }
    }
}));




function Menu () {
    
  const classes = useStyles();
  const [state, setState] = React.useState({top: false});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
    };


    const [scrollPosition, setScrollPosition] = React.useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };


  const list = (anchor) => (
    <div
          className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    <div align="center" style={{backgroundColor:"rgba(136,136,136,0.2)"}}>
    <div style={{padding:"10px"}}><a  href='tel:+233 3039 61491'> <Call style={{marginBottom:"-6px"}}/> +233 3039 61491</a></div>
    <Link to="/register"><Button  variant='outlined'  style={{margin:"10px"}}>Register Now</Button></Link>
    </div>
    <Divider/>
      <List>
        {[<Link to="/">Home</Link>,<Link to="/portfolio">Portfolio</Link>, <Link to="/aboutus">About Us</Link>,  <Link to="/contact">Contact</Link>,  <Link to="/login">Agent Login</Link>].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      
    </div>
  );

    
        
        

    return(
        
        <Router>
            <div className={classes.displayer_big}>
            
             
            { scrollPosition > 80 ? 
                <div class="nav-wrapper containe before" >
                <Link to="/" className="brand-logo logo-container"> <img src={Logo} className="logo_img" alt="" /></Link>
                <ul className="right hide-on-med-and-down">
                    
           
                    <li><Link to="/services">Our Services</Link></li>
                    {/* <li><Link to="/quote">Get a Quote</Link></li> */}
                    <li><Link to="/aboutus">About Us</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                
        
         </div>
                    :



                    <div class="nav-wrapper containe after"  >
                        <Link to="/" className="brand-logo1 logo-container"> <img src={Logo} className="logo_img1" alt="" /> </Link>
                        
                       
                        <ul className="right hide-on-med-and-down">

                            <li><Link to="/services">Our Services</Link></li>
                            {/* <li><Link to="/quote">Get a Quote</Link></li> */}
                            <li><Link to="/aboutus">About Us</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>


            }
         </div>
        
        <div>
       
        
        
        <div className={classes.displayer_small} style={{textAlign:'left', padding:10}}>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><Menu1 /></Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      
      <span style={{marginLeft:'24%'}}>
         <img src={Logo} className="logo_img_small" alt="" />
      </span>
    </div>
        
        
        
        </div>
        
        
       
        
        <Switch>
          <Route path="/portfolio">
            <Portfolio/>
          </Route> 
        
       
        <Route path="/aboutus">
            <Aboutus />
          </Route> 
       
          <Route path="/contact">
            <Contact />
          </Route>  
        <Route path="/services">
            <Services />
          </Route>  
          <Route path="/login">
            <Login />
          </Route>  
          
          <Route path="/register">
            <Register />
          </Route>  
       
          <Route path="/">
            <Main/>
          </Route>
        </Switch>
        
        <Footer/>
        </Router>
        
        
        
        
    )
    
}



export default Menu;