import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Pdf from './cv_joseph.pdf'
import Logo from './img/logo.png';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({

    caro:{ 
        
      backgroundImage:"linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)",
      color:'black',
        padding:'50px',
       
    },
    back:{ 
    backgroundImage:"linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)",
//      backgroundColor:'black',
      color:'black',
        padding:'10px',
        fontSize:14
       
    }
}));


function Example(props){
    const classes=useStyles()
    return (
        
        <div className={classes.back}>
        <div className={classes.caro}>
        <Grid container spacing={8} style={{color:"green"}} >
        <Grid item xs={12} sm={5} md={5} >

                      

                        <Grid  spacing={0} style={{ textAlign: "center", paddingTop: '6px'}}>
                            <div style={{ textAlign: "center" }}>
                                <img src={Logo} width="40%" /> <br/>
                            
                            <h2 style={{color:"green"}} >
                            BlessUp Accounting and Tax Services
                            </h2>
                            <p style={{marginTop:"8px", color:"green"}}>
                                You are not alone!
                                </p>
                            </div>
       
        </Grid>
        </Grid>
        <Grid item  xs={12} sm={3} md={3} >
    <h2 style={{textAlign:'left', color:"green"}}> Quick Link</h2>
        <br/>
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
       <Link to="/contact" >
        Contact Us
       </Link>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
         <Link to='/aboutus'>
        About us
       </Link>
         </Grid>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
        <Link to='/portfolio'>
       Book An Appointment
       </Link>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
       <Link to='/Services'>
        Our Services
       </Link>
         </Grid>
        </Grid>
    
        </Grid>
        
        
        
        <Grid item  xs={12} sm={4} md={4} >
        <h2 style={{textAlign:'left', color:"green"}}> Contact</h2>
        <br/>
        <Grid container spacing={2} style={{textAlign:'left'}}>
         <Grid item xs={2} sm={1} md={1} style={{textAlign:'left', paddingBottom:'0px'}}>
        <WhatsApp/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        <a href="https://wa.me/+233268005074/?text=Hi there, Please send your message"> WhatsApp Chat</a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'left', paddingBottom:'0px'}}>
        <Email/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        <a href='mailto:support@ravinlead.com'>support@ravinlead.com</a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'left', paddingBottom:'0px'}}>
        <Call/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
         <a href='tel:+124 0758 9352'>+124 0758 9352 </a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'left', paddingBottom:'0px'}}>
        <LocationOn/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
         <a>New Carrollton MD, USA</a>
                            </Grid>
                            <br/>
                            <br/>
                            <div className={classes.root} style={{marginTop:"10px"}}>

                                <a href="https://web.facebook.com/ravinlead.techgh/" style={{margin:10}} >
                                    <Facebook style={{ fontSize: "30px" }} fontSize="20px"/>
                                </a>


                                <a href="https://www.linkedin.com/company/ravinlead-tech-hub/" style={{ margin: 10 }} >
                                    <LinkedIn style={{ fontSize: "30px" }} />
                                </a>

                                <a href=""  style={{ margin: 10 }}>
                                    <Instagram style={{ fontSize: "30px" }} />
                                </a>


                            </div>
        </Grid>
    
        </Grid>
        
        

      </Grid>
 </div>
     <p style={{textAlign:'center', fontSize:11}}>
      Copyright 2022 BlessUp Accounting and Tax Services all rights reserved <br/><br/>
                Powered by <a href="http://ravinlead.com" target="_blank">RavinLead Tech Hub</a>
</p>
        </div>
        
   
        
        
    

    )
}


export default Example


