import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import { makeStyles } from '@material-ui/core/styles';

import AOS from 'aos';

import Grid from '@material-ui/core/Grid';
import TiltPhaseSix from './TiltPhaseSix';
import User from './img/port/Component301.png';



import Particles from 'react-tsparticles'
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"


const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large: {
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(20),
            height: theme.spacing(20),
            marginLeft: '20%'

        }, [theme.breakpoints.up('lg')]: {
            width: theme.spacing(43),
            height: theme.spacing(43),
            marginLeft: '24%',
            marginTop: '20%'
        }

    },

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));



function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });

    var items = [
        {
            name: "Scientific Computation",
            image: Para0,
            description: "I love scientific computation"
        }
    ]









    return (

        <div>










            <Carousel swipe={true} touch={true} animation={"slide"} >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>




            <div>









                <div style={{ backgroundSize: "cover", paddingTop: 30, paddingBottom: 30 }}>


                    <Container maxWidth="md" style={{ textAlign: "left", backfroundColor: "rgba(255,255,255,0.1)" }}>


                        <div data-aos="fade-up">

                            <br />
                            <br />
                            <b>Ravinlead Tech Hub</b>
                            <br />
                            <br />
                            <p>RavinLead Tech Hub (RTH) is a business entity registered with the Registrar General Department of Ghana that deals with the development of website and Mobile Applications, and Graphic Design. Mr. Ntori Joseph Ngissah is the founder of RTH. This company was founded in January 2020.</p>
                            <br/>
                            <p>Information Technology has become very essential to human, as it has become the main tool for solving major problems we face daily. The relevance of RTH is dependent on its ability to make life easier by providing solutions to everyday problems in the field of communication, marketing, education, entertainment and other businesses by using IT solutions.</p>
                            <br />
                            <p>RavinLead Tech Hub (RTH) is situated in Ghana and seeks to find solutions to major problems faced in Ghana. Notwithstanding the aforementioned statement, our scope of operation is extend to foreign countries as well, we endeavour to go international to increase the reach of target market, explore in our field of specialization and maintain the international standards of operation for the company.</p>
                            <br />
                            <p>The company stands as an incubation hub and job avenue that brings together IT specialists to collaborate and provide solutions while pursuing their career. We aim at bringing on board fresh graduates and students from the IT field as Interns and permanent workers to practice and improve upon their skills. We believe we can create a reliable platform for upcoming programmers to build projects, get employed and provide solutions to technological problem.</p>

                            <br />







                        </div>


                    </Container>

                </div>





            </div>




            <br />
            <br />
            <br />
            <br />

        </div>


    )
}

function Item(props) {
    const classes = useStyles()
    return (

        <div>
            <div style={{ position: "absolute", width: "100%" }}>
                <Grid container spacing={5} >
                    <Grid sm={12} lg={5} md={5} style={{ marginTop: "8%" }}>




                        <div id="App" data-aos={"flip-right"} className={classes.large} style={{ color: "white", fontSize: "33px", marginTop: 30, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} width="100%" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                            <p className={classes.larger}>
                                We Provide Tech Solutions
                            </p>

                            <h4 className={classes.smaller}>
                                We Provide Tech Solutions
                            </h4>


                            <Button style={{ backgroundImage: "linear-gradient(315deg, #D29E00 0%,  #FFDE3B 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button>
                        </div>


                    </Grid>

                    <Grid sm={12} lg={5} md={5} style={{ marginTop: "17%" }}>




                    </Grid>

                </Grid>



            </div>

            <Particles className="particle" style={{ position: "", zIndex: 1, height: "100%", width: "200%", backgroundColor: "red" }}
                id="tsparticles"
                options={{
                    background: {
                        color: {
                            value: "",
                        },

                    },

                    fpsLimit: 30,
                    interactivity: {
                        detectsOn: "canvas",
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: false,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 0.5,
                                opacity: 0.8,
                                size: 40,
                            },
                            push: {
                                quantity: 10,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: ["#C39300", "#D5A100", "#FFDE3B"]
                        },
                        links: {
                            color: "#FFDE3B",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 0.5,
                        },
                        collisions: {
                            enable: false,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outMode: "bounce",
                            random: true,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 900,
                            },
                            value: 100,
                        },
                        opacity: {
                            value: 0.2,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: true,
                }}
            />




        </div>




    )
}


export default Example


