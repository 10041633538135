import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from "axios"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '47%',
    },
  },
}));

export default function MultilineTextFields() {
  const classes = useStyles();
  const [loading, setloading] = React.useState(!true);
  const [message, setMessage] = React.useState(!true);
  const [severity, setSeverity] = React.useState(!true);
  const [open_noti, setOpen_noti] = React.useState(!true);

   const sender =  () => {
setloading(true)
           
            const poster = async () => {
                await axios.post("https://localhost:3001/api/auth/sender", {
                    firstname: document.getElementById("Firstname").value,
                    lastname: document.getElementById("lastname").value,
                    contact: document.getElementById("contact").value,
                    email: document.getElementById("email").value,
                    message: document.getElementById("message").value,
                }).then((res) => { if (res.status === 200) {setloading(!true); setSeverity("success"); setMessage("Your message is successfully sent"); setOpen_noti(!open_noti)} else {setloading(!true); setSeverity("warning"); setMessage("error sending message"); setOpen_noti(!open_noti)} })
            }
            poster()
           
        }

   



   const handleClick_noti = () => {
        setOpen_noti(true);
    };

    const handleClose_noti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen_noti(false);
    };

  

  return (
    <form className={classes.root} onSubmit={()=>sender()} noValidate autoComplete="off">
     
      <Snackbar open={open_noti} autoHideDuration={6000} onClose={handleClose_noti}>
                    <Alert onClose={handleClose_noti} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
     
      <div>
        
        <TextField
          id="Firstname"
          label="Firstname"
          placeholder="Firstname"
          multiline
          variant="outlined"
        />
        <TextField
          id="lastname"
          label="Lastname"
          placeholder="Lastname"
          multiline
          variant="outlined"
        />
       </div> 
      <div>
        <TextField
          id="Email"
          label="Email"
          placeholder="Email"
          multiline
          variant="outlined"
        type="Email"
        />
        <TextField
          id="Contact"
          label="Contact"
          placeholder="Contact"
          multiline
          variant="outlined"
       type="tel"
        />
        <TextField
          id="message"
          label="Message"
          multiline
          rows={4}
           placeholder="Enter Message"
          variant="outlined"
      style={{width:"97%"}}
        />
      </div>
      <div style={{textAlign:"center"}}>
      {
        loading?
      <Button variant="contained" color="primary" disabled>
 Loading...
</Button> : <Button variant="contained" style={{backgroundColor:"green", color:"white"}} onClick={()=>sender()} color="primary">
 Submit
</Button>
      }
      </div>
    </form>
  );
}


