import React from 'react';
import { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import {Button} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Green from './img/sustainable.jpg';
import Energy from './img/energy.jpg';
import Use2 from './img/use2.jpg';
import { Parallax } from 'react-parallax';
import User2 from './img/port/Component33.png';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import User from './img/port/Component301.png';
import Web from './img/logo/FriendlyandResponsiveLogos.png';
import Mobile from './img/logo/mobile_app.png';
import Graphic from './img/logo/spage.png';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Book from './img/sam/book.svg';
import Para0 from './img/logo/183850_raven-hd-wallpapers_1920x1200_h.jpg';
import Para1 from './img/sam/Compare-Salaries-FA-07-12-2018.jpg';
import view1 from './img/sam/view1.jpg';
import view2 from './img/sam/view2.jpg';
import Avatar from '@material-ui/core/Avatar';
import TiltPhaseSix from './TiltPhaseSix';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Cvmain from "./cv_joseph.pdf"
import Typography from '@material-ui/core/Typography';
import AOS from 'aos';
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import GitHub from '@material-ui/icons/GitHub';
import Particles from 'react-tsparticles' 
import Portimage from './portimage' 
import "aos/dist/aos.css";
import Trip from "./img/trip-animate.svg"
import Residency from "./img/sam/hotel-room-bed.jpg"
import Travel from "./img/sam/happy-woman-sitting-car-traveling-summer-season-sea-resting-special-day-vacation_36577-127.webp"
import Diploma from "./img/sam/motorcade-security-service.jpg"
import Conference from "./img/sam/pa6.jpg"
import Covered from "./img/sam/happy-family-16482074034x3.jpg"
import Flying from "./img/sam/view1.jpg"
import Room from "./img/sam/weed-room-service-scaled-e1589492623693.jpg"
import Escort from "./img/sam/eca32da3068d23cb5fbc88a0a0725317.welfare.webp"
import Tour from "./img/sam/101100110_m.jpg"
import Training from "./img/sam/Seminar_general_web.jpg"
import "./index.css";
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CountUp from 'react-countup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';



import  {
  Link
} from 'react-router-dom';

const useStyles=makeStyles((theme)=>({
   
  large: {
       [theme.breakpoints.down('xs')]: {
      display: "none",
        
         

    }, [theme.breakpoints.up('lg')]: {
       width: theme.spacing(43),
      height: theme.spacing(43),
      display: "block",
          
       
    }
   
    },

     larger: {
         [theme.breakpoints.up('xs')]: {
             color: "white",
             fontWeight:"bolder",
              display: "block",
             

    }, [theme.breakpoints.down('lg')]: {
             display: "none"
    }
   
    },
      smaller: {
         [theme.breakpoints.down('xs')]: {
              display: "block",
            

    }, [theme.breakpoints.up('lg')]: {
              color: "white",
                display: "none",
            
    }
   
    },

    image: {
    position: 'relative',
    height: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 0,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },

    root: {
      
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
    
    
    
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:550,
          paddingTop: '15%'
         
    }
    }
}));



const insideStyles = {
  background: "rgba(255,255,255,0.5)",
  padding: 10,
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize:"30px",
  fontWeight:"bolder",
  transform: "translate(-50%,-50%)"
};


const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function Example(props){
    AOS.init({
    // initialise with other settings
    duration : 700
  });
    const classes = useStyles();
    
    var items = [
        {
            name: "I AM JOSEPH NGISSAH",
            image: Para0,
            description: "I love to solve problems with code"
        }
    ]

    const [scrollPosition, setScrollPosition] = React.useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

 
    useEffect(() => {
        

        return () => {
            handleClickOpen()
        };
    }, [2000] );

    const handleScroll = () => {
        const position = window.pageYOffset;
       
        setScrollPosition(position);
    };

    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [country, setcountry] = React.useState('Ghana');

  const handleChangecountry = (event) => {
    setcountry(event.target.value);
  };

    return (
        
        <div>
        
            <Carousel swipe={true} touch={true} animation={""} indicators={false} >
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>




    <div>
           
        
            
                <div>
                   
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Modal title
                        </DialogTitle>
                        <DialogContent dividers>
                            <form className={classes.root} noValidate autoComplete="off">
                                
                                <TextField id="outlined-basic" style={{margin:5}} label="Outlined" variant="outlined" />
                                <TextField id="outlined-basic" style={{ margin: 5 }} label="Outlined" variant="outlined" />

                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Save changes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            <br/>
          <div className='topper' style={{backgroundSize:"cover", paddingBottom:50}}>
            
            
            <Container  maxWidth="lg" style={{textAlign:"left", backfroundColor:"rgba(255,255,255,0.1)" , paddingTop:40, paddingBottom:40}}>   
                
             <Grid container spacing={3}>

            <Grid item xs={12} lg={3}>
              <div  data-aos="fade-up" className="zoomer"   style={{border:"none", borderRadius:"0px", padding:5, borderWidth:"0.1px", borderColor:"goldenrod", alignContent:"center", backgroundColor:"goldenrod", transition:"0.8s ease-in-out"}}>

                
               <img  data-aos="flip-down"   src={Residency} width="100%" />
               <p data-aos="fade-up" style={{marginTop:"-18px"}}  align="center" > <span  data-aos="fade-up" style={{backgroundColor:"white", border:"solid",padding:"10px", borderRadius:"0px",borderWidth:"4px", borderColor:"goldenrod",color:"goldenrod"}}><b>Residency Reservation</b> </span>  </p>


              </div>
            </Grid>
           
              <Grid item xs={12} lg={3}>
              <div  data-aos="fade-up" className='zoomer' style={{border:"none", borderRadius:"0px", padding:5, borderWidth:"0.1px", borderColor:"goldenrod", alignContent:"center", backgroundColor:"goldenrod", transition:"0.8s ease-in-out"}}>

                
               <img   data-aos="flip-left"  src={Travel} width="100%" />
               <p data-aos="zoom-in" style={{marginTop:"-18px"}}  align="center"> <span  data-aos="fade-up" style={{backgroundColor:"white", border:"solid",padding:"10px", borderRadius:"0px",borderWidth:"4px", borderColor:"goldenrod",color:"goldenrod"}}><b>Travel & Tour</b> </span>  </p>

              </div>
            </Grid>
           
           
            
              <Grid item xs={12} lg={3}>
              <div  data-aos="fade-up" className="zoomer"  style={{border:"none", borderRadius:"0px", padding:5, borderWidth:"0.1px", borderColor:"goldenrod", alignContent:"center", backgroundColor:"goldenrod", transition:"0.8s ease-in-out"}}>

                
               <img  data-aos="flip-right"   src={Diploma} width="100%" />
               <p data-aos="fade-up" style={{marginTop:"-18px"}}  align="center"> <span  data-aos="fade-up" style={{backgroundColor:"white", border:"solid",padding:"10px", borderRadius:"0px",borderWidth:"4px", borderColor:"goldenrod",color:"goldenrod"}}><b>Diplomatic Escort</b> </span>  </p>


              </div>
            </Grid>
           
           
             
              <Grid item xs={12} lg={3}>
              <div  data-aos="fade-up" className="zoomer"  style={{border:"none", borderRadius:"0px", padding:5, borderWidth:"0.1px", borderColor:"goldenrod", alignContent:"center", backgroundColor:"goldenrod", transition:"0.8s ease-in-out"}}>

                
               <img  data-aos="flip-up"   src={Conference} width="100%" />
               <p data-aos="fade-up" style={{marginTop:"-18px"}}  align="center" > <span  data-aos="fade-up" style={{backgroundColor:"white", border:"solid",padding:"10px", borderRadius:"0px",borderWidth:"4px", borderColor:"goldenrod",color:"goldenrod"}}><b>Conference & Training</b> </span>  </p>


              </div>
            </Grid>
           
             </Grid>




    </Container> 

    <Container>
<br/>
<br/>
    <h1 data-aos="zoom-in" style={{textAlign:"center", fontWeight:"bolder", background: "linear-gradient(180deg,  gold 0%, goldenrod 74%)", webkitBackgroundClip:"text",webkitTextFillColor:"transparent" }}>We've Got You Covered</h1>
            <p data-aos="zoom-in" style={{color:"grey", textAlign:"center"}}>We provide the best services for our clients to have a comfrotable stay</p>
<br/>
<br/>
           <Grid container >

           <Grid lg={7} md={6} sm={12}>

<img data-aos="flip-left"  src={Covered} width="100%" />
</Grid>

           <Grid lg={5} md={6} sm={12} className="styler" textAlign="center">

<Box
data-aos="fade-up"
      boxShadow={3}
      m={1}
      p={1}
      className="overlap"
      
      style={{ width: '100%', border:"solid", borderColor:"goldenrod", borderWidth:"5px", transition:"0.6s ease-in-out"}}
    >
      <List component="nav" aria-label="main mailbox folders">
      <ListItem >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={<h1 style={{fontWeight:"bolder", color:"goldenrod"}}>Comfort</h1>} secondary="hi there how are you" />
      </ListItem>
    </List>
    </Box> 

<Box
data-aos="fade-up"
      boxShadow={3}
      m={1}
      p={1}
      className="overlap"
      style={{ width: '100%', border:"solid", borderColor:"goldenrod", borderWidth:"5px", transition:"0.8s ease-in-out"}}
    >
      <List component="nav" aria-label="main mailbox folders">
      <ListItem >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={<h1 style={{fontWeight:"bolder", color:"goldenrod"}}>Security</h1>} secondary="hi there how are you" />
      </ListItem>
    </List>
    </Box> 
  
  <Box
  data-aos="fade-up"
      boxShadow={3}
      m={1}
      p={1}
      className="overlap"
      style={{ width: '100%', border:"solid", borderColor:"goldenrod", borderWidth:"5px",  transition:"0.8s ease-in-out"}}
    >
      <List component="nav" aria-label="main mailbox folders">
      <ListItem >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={<h1 style={{fontWeight:"bolder", color:"goldenrod"}}>Reliable</h1>} secondary="hi there how are you" />
      </ListItem>
    </List>
    </Box> 


</Grid>





           </Grid>

    </Container>

</div>
<div style={{ paddingTop:0, paddingBottom:0,backgroundImage:"linear-gradient(315deg, goldenrod 0%,  goldenrod 74%)"}}>
<div  style={{padding:0}}>
    
<Grid container spacing={0}>
<Grid xs={6} lg={6} sm={12} md={6}>
<Parallax bgImage={Room} strength={100}>
      <div style={{ height: 400, color:"goldenrod" }}>
        <div data-aos="fade-in" style={insideStyles}>Residency Reservation</div>
      </div>
    </Parallax>
</Grid>

<Grid xs={6} lg={6} sm={12} md={6}>

<Container maxWidth="md" style={{padding:"20px"}}>



</Container>

</Grid>




     </Grid>




</div>
</div>


<div style={{ paddingTop:0, paddingBottom:0,backgroundImage:"linear-gradient(315deg, red 0%,  red 74%)"}}>
<div  style={{padding:0}}>
    
<Grid container spacing={0}>
<Grid xs={6} lg={6} sm={12} md={6}>

<Container maxWidth="md" style={{padding:"20px"}}>



</Container>

</Grid>

<Grid xs={6} lg={6} sm={12} md={6}>
<Parallax bgImage={Tour} strength={100}>
      <div style={{ height: 400, color:"red" }}>
        <div data-aos="fade-in" style={insideStyles}>Travel & Tour</div>
      </div>
    </Parallax>
</Grid>





     </Grid>




</div>
</div>

 
<div style={{ paddingTop:0, paddingBottom:0,backgroundImage:"linear-gradient(315deg, black 0%,  black 100%)"}}>
<div  style={{padding:0}}>
    
<Grid container spacing={0}>
<Grid xs={6} lg={6} sm={12} md={6}>
<Parallax bgImage={Escort} strength={100}>
      <div style={{ height: 400, color:"black" }}>
        <div data-aos="fade-in" style={insideStyles}>Diplomatic Escort</div>
      </div>
    </Parallax>
</Grid>

<Grid xs={6} lg={6} sm={12} md={6}>

<Container maxWidth="md" style={{padding:"20px"}}>



</Container>

</Grid>




     </Grid>




</div>
</div>



<div style={{ paddingTop:0, paddingBottom:0,backgroundImage:"linear-gradient(315deg, white 0%,  white 74%)"}}>
<div  style={{padding:0}}>
    
<Grid container spacing={0}>
<Grid xs={6} lg={6} sm={12} md={6}>

<Container maxWidth="md" style={{padding:"20px"}}>



</Container>

</Grid>

<Grid xs={6} lg={6} sm={12} md={6}>
<Parallax bgImage={Training} strength={100}>
      <div style={{ height: 400, color:"Black" }}>
        <div data-aos="fade-in" style={insideStyles}>Conference & Training</div>
      </div>
    </Parallax>
</Grid>





     </Grid>




</div>
</div>






            
    
    </div>




 

    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
        <div>
<div style={{position:"absolute", width:"100%"}}>
<Grid container>


<Grid item xs={12} style={{ marginTop:"8%", textAlign:"center"}}>


                        <div id="App"  className={classes.large}  style={{ textAlign:"center", color: "white", fontSize: "33px", marginTop: 0, position: "relative", zIndex: 1, width:"100%" }}>
                  
                           
<span style={{ padding:"10px", width:"40%"}}>
<h2  data-aos="flip-right" style={{fontSize:"250%", fontWeight:"bolder", background: "linear-gradient(200deg,  #C69522 30%, red 104%)", webkitBackgroundClip:"text",webkitTextFillColor:"transparent"}}>KOZMIC HOSPITALITY</h2>
<h4  data-aos="flip-left" style={{fontWeight:"100", color:"RED"}}>PLAN YOUR TRIP WITH US</h4>
                            <h1  color="black" >
                               <span style={{fontSize:"80px",fontWeight:"1000", marginBottom:"-45px", background: "linear-gradient(90deg,  #C69522 0%, red 74%)", webkitBackgroundClip:"text",webkitTextFillColor:"transparent"}}> <CountUp delay={3} end={2300} /> +</span> <br/>
                            </h1>
                            <p style={{fontSize:"25px", marginTop:"-6px", textAlign:"center", background: "linear-gradient(200deg,  red 30%, #C69522 104%)", webkitBackgroundClip:"text",webkitTextFillColor:"transparent"}}>Trips has been Planned</p>

</span>
<Button style={{border:"solid", backgroundColor:"rgba(255,255,255,0.3)",borderWidth:"3px", borderColor:"red", color:"white", marginTop:"20px"}}>Plan Your Trip Now</Button>
                        </div>

                        <div id=""  data-aos={"flip-right"} className={classes.smaller}  style={{textAlign:"center", color: "white", fontSize: "33px", marginTop: "-10px", position: "relative", zIndex: 1, width:"100%" }}>
                  
                           
<span style={{ padding:"0px", width:"40%"}}>
<h1>Be lucky to Win a Car</h1>
                            <h1  style={{fontSize:"45px"}}>
                               <b>200,000</b> <br/>
                            </h1>
                        <small style={{fontSize:"20px"}}>Applicants Remaining (out of 200,000)</small>

</span>
                        </div>
                        

</Grid>


</Grid>


          
</div>

             <Particles className="particle" style={{position:"",zIndex:1, height:"100%", width:"200%", backgroundColor:"red" }}
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#C39300", "#D5A100", "#FFDE3B"]
                },
                links: {
                    color: "#FFDE3B",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 0.5,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}
      />
            
            
        
        
        </div>
        
        
    

    )
}


export default Example


